import './css/Modal.css';

import styled from "styled-components";

import {motion, AnimatePresence} from 'framer-motion';
import React from "react";

const LogoutButton = styled.button`
  position: absolute;
  //background-color: #3498DB;
  
  top: 0;
  left: 0;
  
  border: unset;
  padding: 8px 16px;
  
  //color: white;
  
  :hover{
    background-color: lightgrey;
  }
  
  i{
    padding-left: 6px;
  }
  
`;

const ChooseModal = ({
    handleClose,
    show,
    showCloseButton,
    children,
    headerText,
    footerText,
    customStyle,
    infoType,
    logout,
    showLogout
}) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";

    return (
        <AnimatePresence>
            {show &&(
                <motion.div
                    initial={{
                        opacity: 0
                    }}
                    animate={{
                        opacity: 1,
                        transition: {
                            duration: 0.05
                        }
                    }}
                    exit={{
                        opacity: 0,
                        transition: {
                            duration: 0.3
                        }
                    }}
                    className={showHideClassName+" z-index:100"}>

                    <motion.div
                        initial={{
                            scale: 0
                        }}
                        animate={{
                            scale: 1
                        }}
                        className={"modal-main "+customStyle}>
                        {
                            (showCloseButton) ? <span onClick={handleClose} className="display-topright close_btn">×</span> : <></>
                        }
                        {/*<span onClick={handleClose} className="display-topright close_btn">×</span>*/}
                        <header className="container center">
                            {
                                (showLogout)
                                ?
                                <LogoutButton onClick={() => {logout()}}>
                                    <i className="fas fa-sign-out-alt" style={{transform: "rotate(180deg)"}}></i>Logout
                                </LogoutButton>
                                :
                                <></>
                            }

                            <h2>
                            {
                                (infoType && infoType==="WARN")
                                ?
                                    <i className="fas fa-exclamation-triangle" style={{paddingRight: "10px"}}></i>
                                :
                                    <></>
                            }
                            {/*<i className="far fa-question-circle"></i> */}
                            {headerText}
                            </h2>
                        </header>

                            {children}

                        {/*<footer className="container">*/}
                        {/*    /!*<button type="button" className="modal-button" onClick={openMetaMaskLink}>*!/*/}
                        {/*    /!*<button type="button" className="modal-button">*!/*/}
                        {/*    /!*    <div className="button-content">*!/*/}
                        {/*    /!*        <img src="/images/wallet-logos/metamask.svg" alt="MetaMask"></img>*!/*/}
                        {/*    /!*        <span>Download MetaMask</span>*!/*/}
                        {/*    /!*    </div>*!/*/}
                        {/*    /!*</button>*!/*/}
                        {/*</footer>*/}

                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>

    );
};


export default ChooseModal;