import React, {useMemo, useState} from 'react';
import {useUserService} from "../../services/userService/UserServiceProvider";
import {useAPI} from "../../api/v2/apiV2";
import ChooseModal from "./Modal";
import {Checkbox, FormControlLabel} from "@mui/material";
import {FooterDiv, ItemCard, SelectionDiv} from "./Modal.styles";


function ClientSelectionModal(props){

    const {user: { clients } = {}, setClient, setUser} = useUserService(); //clients of user.client

    const {user: {changeClient} = {}, logout} = useAPI();

    const [show, setShow] = useState(props.show);

    useMemo(() => {
        console.log("Clients", clients)
    }, [clients]);

    const handleClientSelection = async(selectedClient) => {

        console.log("selectedClient", selectedClient)
        setClient(selectedClient);
        console.log(setClient, setUser);
        const response = await changeClient(selectedClient.id);
        console.log("response", response)
        setUser(response.message)
    }

    return(
        <ChooseModal
            show={show}
            handleClose={() => {setShow(false)}}
            headerText="Choose your Client"
            logout={logout}
            showLogout={true}
        >
            <SelectionDiv>
                {
                    clients.map((client, key) => {
                        return (
                            <ItemCard
                                key={key}
                                onClick={async() => { await handleClientSelection(client) } }
                            >
                                <div className={"cardInner"}>
                                    <div className={"coverFront"}>
                                        <h5>{client.name}</h5>
                                        {/*<h5>{roleObject.roleName}</h5>*/}
                                        {/*<p>{roleText(roleObject.role)}</p>*/}
                                    </div>
                                </div>
                            </ItemCard>
                        )
                    })
                }
            </SelectionDiv>

            <FooterDiv>
                {/*<FormControlLabel*/}
                {/*    label="Save selected Role as default"*/}
                {/*    title={"Checking the box will save the selected Role as default for next login"}*/}
                {/*    control={<Checkbox checked={saveDefaultRole} onChange={()=>{setSaveDefaultRole(!saveDefaultRole)}} />}*/}
                {/*/>*/}
            </FooterDiv>

        </ChooseModal>
    )

}

export default ClientSelectionModal;