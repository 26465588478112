import React, {createContext, useContext, useEffect, useMemo, useState} from "react";
import {useCookie} from "../storageService/Storage";
import {useLiveQuery} from "dexie-react-hooks";
import {browserDB} from "../storageService/IndexedDB";
import {toast} from "react-toastify";


const UserServiceContext = createContext(undefined);

export function UserServiceProvider({children}) {

    const roleQuery = useLiveQuery(() => browserDB.defaultRole.toArray(), []);	//DefaultRole

    const [account, setAccount] = useState(null);
    const [jwt, setJWT, getJWT] = useCookie("jwt", "");

    const [activeRole, setActiveRole] = useState(null);
	const [storedRole, setStoredRole] = useState(null);
    const [activeClient, setActiveClient] = useState(null);

    const [init, setInit] = useState(false);
    const [finalInit, setFinalInit] = useState(false);

    const setUser = (user) => {
        setAccount(user)
    };
    const setRole = (role) => {
        setActiveRole(role)
    };

    const setClient = (client) => {
        setActiveClient(client)
    };

    const isUserAuthenticated = () => {
        // console.log("Is user authenticated?", getJWT() !== undefined && getJWT() !== "")
        return (getJWT() !== undefined && getJWT() !== "")
    }
    const isLoggedIn = () => {
        return (account !== null && jwt !== undefined)
    }

    const hasActiveRole = (queryRole) => {
        let storedActiveRole = queryRole.filter(role => role.id === account.email)[0];
        if (storedActiveRole) {
            return storedActiveRole.length === 1;
        } else {
            return false;
        }
    }

	const updateStoredDefaultRole = (role) => {
        console.log("Updating Stored-Role: ", role)
        browserDB.defaultRole.update(account.email, {role: role.role, roleName: role.roleName}).then((updated) => {
            if(updated){
                console.log(updated)
                setStoredRole(role)
            }else{
                console.warn("Primary-Key["+account.email+"] not found!")
            }
        }).catch((error) => {
            console.log(error)
        })

	}

    const handleActiveRole = (roleQuery) => {
        let storedActiveRole = roleQuery.filter(role => role.id === account.email)[0];
        if (storedActiveRole) {	//Already stored
            setRole(storedActiveRole)		//Setting ActiveRole
            setStoredRole(storedActiveRole)
        } else {
            //If just one Role -> Set it as default-ActiveRole
            if (account.roles.length === 1) {
                storeDefaultRole(account.roles[0])
                setRole(account.roles[0])
            } else {
                //Do nothing -> Set init to complete so the Modal will open
            }
        }

    }
    const storeDefaultRole = (role) => {
        browserDB.defaultRole.add({
            id: account.email,
            // email: account.email,
            role: role.role,
            roleName: role.roleName
        })
        setRole(role)
        setStoredRole(role)
    }

    const reset = () => {
        setAccount(null);
        setActiveRole(null);
        setActiveClient(null);
        setFinalInit(false);
        setInit(false);
    }

    // const handleUserLogin_old = (roleQuery) => {
    //
    //     if (isLoggedIn() && !hasActiveRole(roleQuery)) {
    //         //If has Client-Keys(is NOT first SUPERUSER) AND has more than 1 Client
    //         //But SUPERUSER can also has a Client(Superuser-Client)
    //         if (account.keys) {
    //             if (account.keys.length > 1 || account.multiClientUser) {
    //                 toast.warn("MultiClient not implemented")
    //             } else {
    //                 //Setting Client
    //                 setClient(account.clients[0]);
    //             }
    //         } else {
    //
    //             if(!account.keys && !account.clients){
    //                 //
    //             }else{
    //                 setClient(account.clients[0])
    //             }
    //
    //             console.log(roleQuery)
    //             // toast.warn("Should be Superuser")
    //             //Do nothing -> should be SUPERUSER
    //         }
    //         //Check if has activeRole already set
    //         console.log(roleQuery)
    //         handleActiveRole(roleQuery)
    //         setFinalInit(true);
    //     }
    // }

    const handleUserLogin = (roleQuery) => {

        if (isLoggedIn() && !hasActiveRole(roleQuery)) {

            console.log(account)

            // if(account.clients.length === 1){
            //     setClient(account.clients[0])
            // }

            if(account.roles.find(role => role.role === "SUPERUSER")){
                handleActiveRole(roleQuery)
                setFinalInit(true);
            }else{
                if(account.activeClientId){
                    setClient(account.clients.find(client => client.id === account.activeClientId))

                    //Check if has activeRole already set
                    console.log(roleQuery)
                    // handleActiveRole(roleQuery)
                    // setFinalInit(true);
                }else{
                    console.log("Choose Client!")
                }
            }





            //If has Client-Keys(is NOT first SUPERUSER) AND has more than 1 Client
            //But SUPERUSER can also has a Client(Superuser-Client)
            // if (account.keys) {
            //     if (account.keys.length > 1 || account.multiClientUser) {
            //         toast.warn("MultiClient not implemented")
            //     } else {
            //         //Setting Client
            //         setClient(account.clients[0]);
            //     }
            // } else {
            //
            //     if(!account.keys && !account.clients){
            //         //
            //     }else{
            //         setClient(account.clients[0])
            //     }
            //
            //     console.log(roleQuery)
            //     // toast.warn("Should be Superuser")
            //     //Do nothing -> should be SUPERUSER
            // }


            // //Check if has activeRole already set
            // console.log(roleQuery)
            // handleActiveRole(roleQuery)
            // setFinalInit(true);
        }
    }

    useMemo(() => {
        if (roleQuery && finalInit === false) {
            handleUserLogin(roleQuery);
        }
    }, [roleQuery])

    useEffect(() => {
        if (finalInit) {
            setInit(true);
        }
    }, [finalInit])

    useEffect(() => {
        console.log("Effect Account triggered", roleQuery, finalInit, account)
        if (roleQuery && finalInit === false) {
            console.log("HandleUserLogin")
            handleUserLogin(roleQuery);
        }
    }, [account, roleQuery])

    useEffect(() => {

        if(account && activeRole && activeClient){
            setFinalInit(true)
        }

    }, [account, activeRole, activeClient])

    return (
        <UserServiceContext.Provider
            value={{
                init,
                finalInit,
                isUserAuthenticated,
                isLoggedIn,
                user: account,
                getUser: () => { return account },
                setUser,
                getToken: () => {return jwt},
                getTokenNotState: () => {return getJWT()},
                // role: {
                //     activeRole,
                //     setRole,
                //     storeDefaultRole
                // },
                activeRole,
                setRole,
                storedRole,
                setDefaultRole: storeDefaultRole,
                updateDefaultRole: updateStoredDefaultRole,
                activeClient,
                setClient,
                reset
            }}
        >
            {children}
        </UserServiceContext.Provider>
    )

}

export function useUserService() {
    const context = useContext(UserServiceContext);
    if (!context) throw Error('useUserService can only be used within the UserServiceProvider component')
    return context;
}

