import React, {useEffect, useState} from 'react';
import API from "../../../api/api";
import {Form} from "react-bootstrap";
import styled from "styled-components";
import BaseSelect from "react-select";

import '../../global/css/Upload.css';
import FixRequiredSelect from "../../utils/FixRequiredSelect";
import {toast} from "react-toastify";
import {ServerEventComponent} from "../../utils/ServerEventComponent";
import {FileList, UploadDiv, UploadPage} from "./Upload.styles";
import {useUserService} from "../../../services/userService/UserServiceProvider";
import LoadingSpinner from "../../../uiElements/LoadingSpinner";


const Select = props => (
    <FixRequiredSelect
        {...props}
        SelectComponent={BaseSelect}
        options={props.options}
    />
);

// toast.configure()

function Upload(props){

    const {activeClient} = useUserService();

    return(<>
        <UploadNew activeClient={activeClient}/>
        </>
    )

}


function ShowFilesAreUploadingModal({initiateShowFilesAreUploadingModal}){
    const [showFilesAreUploadingModal, setShowFilesAreUploadingModal] = useState(false)
    const [showFilesAreUploadingModalStartTime, setShowFilesAreUploadingModalStartTime] = useState(0)

    const startFilesAreUploadingModal = ()=>{
        setShowFilesAreUploadingModal(true)
        setShowFilesAreUploadingModalStartTime(new Date().getTime())
    }
    const requestStopFilesAreUploadingModal = ()=>{
        let earliestUnshowTime = showFilesAreUploadingModalStartTime + 2000
        let now = new Date().getTime()
        let timeDiff = earliestUnshowTime - now
        if(timeDiff <= 0){
            setShowFilesAreUploadingModal(false)
        }
        else{
            setTimeout(requestStopFilesAreUploadingModal, timeDiff)
        }
    }
    useEffect(() => {
        console.log("initiateShowFilesAreUploadingModal", initiateShowFilesAreUploadingModal)
        if(initiateShowFilesAreUploadingModal){
            startFilesAreUploadingModal()
        }
        else{
            requestStopFilesAreUploadingModal()
        }

    }, [initiateShowFilesAreUploadingModal]);
    return (
        <div>
            <div className={showFilesAreUploadingModal ? "w3-modal w3-show" : "w3-modal"}>
                <div className="w3-modal-content w3-round" style={{maxWidth:"600px"}}>
                    <div className="w3-container w3-round w3-padding">
                        <p className={"w3-large"}>Files are being uploaded</p>
                        <LoadingSpinner></LoadingSpinner>
                    </div>
                </div>
            </div>
        </div>
    )

}

const UploadNew = ({ activeClient }) => {
    const [formData, setFormData] = useState({
        client: activeClient,
        period: '',
        platform: '',
    });
    const [selectedFiles, setSelectedFiles] = useState();
    const [currentFile, setCurrentFile] = useState();
    const [isDraggingSite, setIsDraggingSite] = useState(false);
    const [showFilesAreUploadingModal, setShowFilesAreUploadingModal] = useState(false);

    const serverEvents = ServerEventComponent.getInstance();
    const api = new API();

    const platformOptions = activeClient.instance_settings.allowed_platforms.map((platform) => ({
        value: platform,
        label: platform,
    }));

    useEffect(() => {
        const handleUploadError = (data) => {
            toast.error(data.message.message, { position: toast.POSITION.BOTTOM_CENTER });
        };

        serverEvents.subscribe(ServerEventComponent.Topics.updates.UPLOAD_ERROR, handleUploadError);
        return () => {
            serverEvents.unsubscribe(ServerEventComponent.Topics.updates.UPLOAD_ERROR, handleUploadError);
        };
    }, [serverEvents]);

    const handlePlatformSelections = (selectedOption) => {
        setFormData((prevData) => ({
            ...prevData,
            platform: selectedOption.value,
            client: activeClient,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setShowFilesAreUploadingModal(true);

        api.uploads.newUpload(api.getCookie("jwt"), formData, selectedFiles)
            .then((response) => {
                setShowFilesAreUploadingModal(false);
                if (response.status === 200) {
                    toast.success("Successfully uploaded Report", { position: toast.POSITION.BOTTOM_CENTER });
                    event.target.reset();
                }
            })
            .catch((error) => {
                setShowFilesAreUploadingModal(false);
                toast.error(error.response.data.message, { position: toast.POSITION.BOTTOM_CENTER });
            });

        setSelectedFiles(undefined);
        setCurrentFile(undefined);
    };

    const selectFile = (event) => {
        setSelectedFiles(event.target.files);
        setCurrentFile(event.target.files[0]);
    };

    const selectFileDragDrop = (event) => {
        event.preventDefault();
        setSelectedFiles(event.dataTransfer.files);
        setCurrentFile(event.dataTransfer.files[0]);
        document.getElementById("uploadInputField").files = event.dataTransfer.files;
    };

    const setPeriod = (event) => {
        const date = new Date(event.target.value);
        const [year, month] = date.toISOString().split("T")[0].split("-");
        setFormData((prevData) => ({
            ...prevData,
            period: `${year}${month}`,
        }));
    };

    const getFileLine = () => {
        if (!selectedFiles) return '';
        return Array.from(selectedFiles).map((file, i) => (
            <div key={i}>{file.name}</div>
        ));
    };

    return (
        <UploadPage
            onDrop={selectFileDragDrop}
            onDragOver={(e) => { setIsDraggingSite(true); e.preventDefault(); }}
            onDragEnter={() => setIsDraggingSite(true)}
            onPointerLeave={() => setIsDraggingSite(false)}
        >
            <div id="id01" className={isDraggingSite ? "w3-modal w3-show" : "w3-modal"}
                 onDrop={selectFileDragDrop}
                 onDragOver={(e) => { e.preventDefault(); setIsDraggingSite(true); }}
                 onDragEnter={() => setIsDraggingSite(true)}
                 onClick={() => setIsDraggingSite(false)}
            >
                <div className="w3-modal-content w3-round dropZoneContainer">
                    <div className="w3-container w3-round dropZone">
                        <p><i className={"fa fa-upload dropZoneIcon"}></i></p>
                        <p className={"w3-xlarge"}>Drop File here</p>
                    </div>
                </div>
            </div>

            <h1>Upload File</h1>
            <hr />
            <UploadDiv>
                <p>Please upload your Reports for the RuleEngine Checks</p>
                <ShowFilesAreUploadingModal initiateShowFilesAreUploadingModal={showFilesAreUploadingModal} />
                <form onSubmit={handleSubmit}>
                    {!activeClient.instance_settings.check_period && (
                        <>
                            <label>Period</label>
                            <input type="date" name="check_period" className="period" onChange={setPeriod} required />
                        </>
                    )}

                    <label>Report Standard</label>
                    <Select
                        options={platformOptions}
                        onChange={handlePlatformSelections}
                        required
                    />

                    <label>Report:</label>
                    <input
                        type="file"
                        id="uploadInputField"
                        name="report"
                        accept="application/vnd.ms-excel, .xlsx, .csv, .json"
                        onChange={selectFile}
                        multiple
                        required
                    />
                    {selectedFiles && (
                        <>
                            <label>Selected Files:</label>
                            <div>{getFileLine()}</div>
                        </>
                    )}
                    <input className="uploadButton" type="submit" value="Upload" />
                </form>
            </UploadDiv>
        </UploadPage>
    );
};
export default Upload;