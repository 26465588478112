import styled from "styled-components";

export const SelectionDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 20px 20px;
  gap: 20px;
`;

export const ItemCard = styled.div`
    
  width: 100%;
  height: 220px;
  text-align: center;
  //margin: 20px;
  
  cursor: pointer;
  background-color: transparent;
  
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
    
  .cardInner{

    position: relative;
    width: 100%;
    height: 100%;
    
    border: 2px solid black;
    border-radius: 5px;
    
    //margin: 0px 10px;
    
    text-align: center;
    transition: transform 0.3s;
    transform-style: preserve-3d;
  }
  
  .coverFront{
    
  }
  
  .coverBack{
    transform: rotateY(180deg);
    display: flex;
    justify-content: center;
    align-items: center;
    //background-color: white;
  }
  .coverFront, .coverBack{
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0px 10px;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;

    >p{
      margin: 20px;
      font-size: 0.8em;
    }
  }
`;

export const FooterDiv = styled.div`
    padding: 10px 20px;
`;