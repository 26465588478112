import {EventSourcePolyfill} from "event-source-polyfill";
import http from "../http-common";
import {ServerUrl} from "./Endpoints";
import {useUserService} from "../../services/userService/UserServiceProvider";

export const SseSource = (userToken) => {

    const EventSource =  EventSourcePolyfill;

    let heatbeatSeconds = 21;

    let eventSourceInit = {
        // withCredentials: true,
        // headers: {'Authorization': 'test=test'},
        // lastEventIdQueryParameterName: 'updates',
        headers: {
            'Authorization': 'Bearer '+userToken()
        },
        heartbeatTimeout: heatbeatSeconds * 1000,
        reconnectInterval: 10000

    }

    function decodeJWT(token) {
        const base64Url = token.split('.')[1]; // Den mittleren Teil extrahieren
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // URL-kompatibel in Standard Base64 konvertieren
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );

        return JSON.parse(jsonPayload); // Payload als JSON zurückgeben
    }

    // console.log("SseSource-Token", userToken());

    let eventSource = new EventSource(http.defaults.baseURL+ServerUrl.EVENTS.NOTIFICATION, eventSourceInit);

    // eventSource.onerror = (event) => {
    //     console.error("ERROR", event)
    // }

    eventSource.onopen = (event) => {
        console.log("Connection open", event)
        console.log(decodeJWT(event.target.headers.Authorization.split('Bearer ')[1]));
    }
    //
    // eventSource.onmessage = (event) => {
    //     console.log("New Message")
    //     console.log(event)
    //     // let data = JSON.parse(event.data);
    //     // console.log(data)
    // }
    eventSource.onerror = (event) => {
        console.log("Error", event)
        // console.log(event.target.readyState)
        if (event.target.readyState === EventSourcePolyfill.CLOSED) {
            console.log('eventsource closed (' + event.target.readyState + ')')
        }
        // eventSource.close();
    }

    return eventSource;
}