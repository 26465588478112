
export const HTTP_STATUS_CODE = {

    OK: 200,
    NO_CONTENT: 204,

    NOT_MODIFIED: 304,

    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    UNPROCESSABLE_ENTITY: 422,

    INTERNAL_SERVER_ERROR: 500,
    SERVICE_UNAVAILABLE: 503,

}